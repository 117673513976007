import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBWEnnIyPN7313NddDNljew4miNurCSS2I",
    authDomain: "teleoriental-b6493.firebaseapp.com",
    projectId: "teleoriental-b6493",
    storageBucket: "teleoriental-b6493.appspot.com",
    messagingSenderId: "517787156908",
    appId: "1:517787156908:web:90d6f0edf073ccc211ba09",
    measurementId: "G-3M308P9762"
})

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }